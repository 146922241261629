import React from 'react';
import classNames from 'classnames';
import { graphql, useStaticQuery } from 'gatsby';
import Image from 'gatsby-image/withIEPolyfill';
import DashboardGif from '../animations/dashboard.gif';
import UploadGif from '../animations/upload.gif';
import { Scroll } from '../components/Animation';
import Layout from '../components/Layout';
import { TextPreFooter } from '../components/PreFooter';
import LinkButton from '../components/LinkButton';
import BlueSection from '../components/sections/BlueSection';
import PageHeader from '../components/sections/PageHeader';
import SEO from '../components/seo';
import styles from './styles/about-us.module.scss';

const people = [
  {
    title: 'Justin Lim',
    subtitle: 'Founder & CEO',
    imgKey: 'justin',
  },
  {
    title: 'Wenbo Li',
    subtitle: 'Co-Founder & CTO',
    imgKey: 'wenbo',
  },
  {
    title: 'Kenneth Ganzan',
    subtitle: 'Co-Founder & Sr. Engineer',
    imgKey: 'ken',
  },
  {
    title: 'Shaun Quincey',
    subtitle: 'Investor',
    imgKey: 'shaun',
  },
  {
    title: 'The Ice House',
    subtitle: 'Investor',
    imgKey: 'icehouse',
    imgClassName: styles.icehouse,
  },
  {
    title: 'Mike Ballantyne',
    subtitle: 'Investor',
    imgKey: 'mike',
  },
];

const Section1 = ({ images }) => (
  <>
    <div className={styles.section1}>
      <div
        className={classNames(
          'container',
          styles.section,
          styles.invoiceSection,
        )}
      >
        <div className={styles.left}>
          <Image
            className={styles.invoicesImg}
            fluid={images.invoices.childImageSharp.fluid}
          />
        </div>
        <div className={styles.right}>
          <h1 className={styles.title}>
            Insurance is super important — <br />
            we all know that.
          </h1>
          <p>But why is just about everything about it a bit, well, sucky?</p>
          <p>
            The policies are hard to understand. The renewals can catch you off
            guard. You’re never quite sure if you’ve got too much, too little or
            just enough cover. Or if you’ve got the best deal.
          </p>
          <p>
            And when you contact your insurance companies, the wait times can be
            horrendous.
          </p>
          <h2 className={styles.blue}>
            At Quashed we’re working to change that!
          </h2>
          <LinkButton
            href="/get-started"
            background="#4187f5"
            className={styles.button}
          >
            Sign up today
          </LinkButton>
        </div>
      </div>
    </div>

    <Scroll className={styles.section2}>
      <div
        className={classNames('container', styles.section, styles.dashSection)}
      >
        <div className={styles.left}>
          <h1 className={styles.title}>
            We’ve built the simplest, most transparent platform to help you make
            sense of all of your insurance.
          </h1>
          <p>
            Simply upload pdfs or photos of your policies, and we do the rest.
            We make it easy to see how much you're spending, how much your
            premiums are increasing each year, and when your insurance policies
            are renewing.
          </p>
          <p>
            We help you identify what you have covered and what you don't have
            covered. And we make it simple to compare what other offers are out
            there, and how you can potentially save thousands of dollars.
          </p>
          <h2 className={styles.blue}>
            Ultimately we give you back control over the way you protect your
            life, lifestyle, loved ones and valuables. We make you the boss of
            your insurance.
          </h2>
        </div>
        <div className={styles.right}>
          <img alt="Upload" src={UploadGif} />
        </div>
      </div>
    </Scroll>
  </>
);

const Section2 = ({ images }) => {
  return (
    <BlueSection wrapClassName={styles.peopleWrap}>
      <div className={styles.left}>
        {people.map((p) => (
          <div key={p.title} className={styles.peopleItem}>
            <div className={p.imgClassName}>
              <Image
                className={styles.peopleImg}
                fluid={images[p.imgKey].childImageSharp.fluid}
                alt={p.title}
              />
            </div>
            <div className={styles.title}>{p.title}</div>
            <div className={styles.subtitle}>{p.subtitle}</div>
          </div>
        ))}
      </div>

      <div className={styles.right}>
        <h1 className={styles.title}>The team who are Quashing it.</h1>
        <div>
          <p>
            Our founder, Justin Lim, worked for two of New Zealand’s largest
            banks, helping to make banking simpler and more accessible online
            for Kiwis, before taking up the challenge to do the same for
            insurance.
          </p>
          <p>
            Quashed was launched in 2020 and today Justin is joined by a team of
            talented engineers, marketers, entrepreneurs and backers.
          </p>
        </div>
      </div>
    </BlueSection>
  );
};

const Section3 = ({ images }) => (
  <Scroll className={classNames('container', styles.sectionLast)}>
    <div className={styles.left}>
      <h2>The average New Zealand household will spend</h2>
      <h1 className={classNames(styles.title, styles.blue)}>
        $100,000+ in insurance <br />
        over their lifetime.
      </h1>
      <p>We’ll help make sure every dollar works hard for you.</p>
    </div>
    <div className={styles.right}>
      <Image
        className={styles.nz}
        fluid={images.newZealand.childImageSharp.fluid}
        alt="New Zealand"
      />
    </div>
  </Scroll>
);

const AboutUs = () => {
  const images = useStaticQuery(
    graphql`
      {
        justin: file(relativePath: { eq: "people/v3-justin.png" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 186) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        wenbo: file(relativePath: { eq: "people/v3-wenbo.png" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        ken: file(relativePath: { eq: "people/v3-ken.png" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        shaun: file(relativePath: { eq: "people/v3-shaun.png" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        icehouse: file(relativePath: { eq: "brands/Icehouse.png" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        mike: file(relativePath: { eq: "people/v3-mike.png" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        dashboard: file(relativePath: { eq: "dashboard.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        invoices: file(relativePath: { eq: "policyFiles.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        newZealand: file(relativePath: { eq: "new-zealand.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `,
  );

  return (
    <Layout footerClassName={styles.footer} transparent={true}>
      <SEO
        description="We’ve built the simplest, most transparent platform to help you make sense of all of your insurance."
        title="About us"
      />
      <PageHeader
        className={styles.header}
        wrapClassName={styles.headerWrap}
        subtitleTop="We’re not an insurance company"
        title="We’re a new insurance experience!"
        image={<img alt="About Us" src={DashboardGif} />}
      />
      <Section1 images={images} />
      <Section2 images={images} />
      <Section3 images={images} />
      <TextPreFooter
        subTitleTop="Quashed"
        title="Helping Kiwis save money on insurance"
        buttonText="Start saving today"
      />
    </Layout>
  );
};

export default AboutUs;
